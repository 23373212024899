import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatDividerModule,
  MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatOptionModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatStepperModule,
  MatTabsModule, MatToolbarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { FirebaseUIModule } from 'firebaseui-angular';
import { FileUploadModule } from 'ng2-file-upload';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { SynapsPicsModule } from 'ngx-synaps-pics';
import * as Raven from 'raven-js';
import { environment } from '../environments/environment';
import { versions } from '../environments/versions';
import { AddSiteComponent } from './add-site/add-site.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminComponent } from './admin/admin.component';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { AuthGuardService } from './auth-guard.service';
import { AuthComponent } from './auth/auth.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DesignComponent } from './design/design.component';
import { DomainSettingsComponent } from './domain-settings/domain-settings.component';
import { EditGalleryDialogComponent } from './edit-gallery-dialog/edit-gallery-dialog.component';
import { EditGalleryComponent } from './edit-gallery/edit-gallery.component';
import { EditSiteComponent } from './edit-site/edit-site.component';
import { EventEditDialogComponent } from './event-edit-dialog/event-edit-dialog.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventListComponent } from './event-list/event-list.component';
import { EventService } from './event.service';
import { firebaseUiAuthConfig } from './firebase.config';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { GalleryService } from './gallery.service';
import { GoogleSearchPreviewComponent } from './google-search-preview/google-search-preview.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SiteDashboardComponent } from './site-dashboard/site-dashboard.component';
import { SiteIntegrationsComponent } from './site-integrations/site-integrations.component';
import { SiteResolver } from './site-resolver.service';
import { SiteSettingsComponent } from './site-settings/site-settings.component';
import { SiteUsersComponent } from './site-users/site-users.component';
import { SiteService } from './site.service';
import { SitesResolver } from './sites-resolver.service';
import { SlideEditDialogComponent } from './slide-edit-dialog/slide-edit-dialog.component';
import { SlideEditComponent } from './slide-edit/slide-edit.component';
import { SlideListComponent } from './slide-list/slide-list.component';
import { ThemeSelectorComponent } from './theme-selector/theme-selector.component';
import { TokenInterceptor } from './token.interceptor';
import { UserResolver } from './user-resolver.service';
import { VerifyEmailComponent } from './verify-email/verify-email.component';


export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
    Raven.showReportDialog();
  }
}

export function provideErrorHandler() {
  if (environment.production) {
    Raven
      .config('https://a6f3397f6b6e4148a813966c4a21dace@sentry.io/1200041', {
        release: versions.revision
      })
      .install();

    return new RavenErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AdminComponent,
    NotFoundComponent,
    SiteDashboardComponent,
    AdminHomeComponent,
    GalleryListComponent,
    EditGalleryComponent,
    EditGalleryDialogComponent,
    EditSiteComponent,
    VerifyEmailComponent,
    EventListComponent,
    EventEditComponent,
    EventEditDialogComponent,
    ConfirmDialogComponent,
    GoogleSearchPreviewComponent,
    SiteSettingsComponent,
    DomainSettingsComponent,
    SiteUsersComponent,
    SiteIntegrationsComponent,
    AddSiteComponent,
    DesignComponent,
    ThemeSelectorComponent,
    SlideListComponent,
    SlideEditComponent,
    SlideEditDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    MatGridListModule,
    MatDialogModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatTabsModule,
    LayoutModule,
    FileUploadModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatCheckboxModule,
    ScriptLoaderModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: !environment.production
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    SynapsPicsModule,
    NgxDnDModule
  ],
  entryComponents: [
    EditGalleryDialogComponent,
    EventEditDialogComponent,
    ConfirmDialogComponent,
    SlideEditDialogComponent
  ],
  providers: [
    SiteService,
    GalleryService,
    EventService,
    AuthGuardService,
    SitesResolver,
    UserResolver,
    SiteResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useFactory: provideErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
