import * as tslib_1 from "tslib";
import { from, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from './api.service';
var SiteService = /** @class */ (function (_super) {
    tslib_1.__extends(SiteService, _super);
    function SiteService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentSite = new ReplaySubject(1);
        return _this;
    }
    SiteService.prototype.getCurrentSite = function () {
        return from(this.currentSite);
    };
    SiteService.prototype.setCurrentSite = function (site) {
        localStorage.setItem('site_id', site.id);
        this.currentSite.next(site);
    };
    SiteService.prototype.getSites = function () {
        return this.list('sites').pipe(shareReplay(1));
    };
    SiteService.prototype.getSite = function (site) {
        return this.get("sites/" + site).pipe(shareReplay(1));
    };
    SiteService.prototype.saveSite = function (site) {
        return this.patch("sites/" + site.id, {
            name: site.name,
            logo: site.logo,
            domains: site.domains,
            languages: ['en']
        });
    };
    SiteService.prototype.addSite = function (site) {
        return this.post("sites", {
            name: site.name,
            logo: site.logo,
            domains: site.domains,
            languages: ['en']
        });
    };
    SiteService.prototype.addDomain = function (site, domain) {
        return this.post("sites/" + site.id + "/domains", {
            domain: domain
        });
    };
    SiteService.prototype.removeDomain = function (site, domain) {
        return this.delete("sites/" + site.id + "/domains/" + domain);
    };
    SiteService.prototype.saveIntegrations = function (site) {
        return this.patch("sites/" + site.id, {
            integrations: site.integrations
        });
    };
    return SiteService;
}(ApiService));
export { SiteService };
