<mat-toolbar color="accent">
    <span>Create your website</span>
</mat-toolbar>

<mat-horizontal-stepper linear="true" #stepper>
  <mat-step [stepControl]="editSite.form">
    <ng-template matStepLabel>Basic Informations</ng-template>
    <app-edit-site #editSite></app-edit-site>
    <div>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="domainSettings.form">
    <ng-template matStepLabel>Domains</ng-template>
    <app-domain-settings #domainSettings></app-domain-settings>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    You are now done.
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
