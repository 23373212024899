import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EditGalleryDialogComponent } from '../edit-gallery-dialog/edit-gallery-dialog.component';
import { GalleryService } from '../gallery.service';
import { Gallery, Site } from '../models';
import { tap, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.scss']
})
export class GalleryListComponent implements OnInit {
  galleries: Gallery[];

  private site: Site;

  constructor(
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.data.pipe(
      tap((data: {site: Site}) => this.site = data.site),
      switchMap((data: {site: Site}) => this.galleryService.getSiteGalleries(data.site.id)),
      map((galleries: Gallery[]) => galleries.filter((gallery: Gallery) => gallery.tags.length === 0))
    )
      .subscribe((galleries: Gallery[]) => {
        this.galleries = galleries;
      });

  }

  addGallery(gallery: string) {
    this.dialog.open(EditGalleryDialogComponent, {
      disableClose: true,
      minWidth: '300px',
      maxWidth: '90%',
      maxHeight: '90%',
      data: {
        gallery: gallery,
        siteId: this.site.id
      }
    }).afterClosed().subscribe((savedGallery: Gallery) => {
      if (!savedGallery) {
        return;
      }

      if (gallery) {
        this.galleries[this.galleries.findIndex(g => g.id === gallery)] = savedGallery;
      } else {
        this.galleries.push(savedGallery);
      }
    });
  }

  removeGallery(gallery: Gallery) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'Are you sure to remove this gallery? You will loose all of it\'s images.',
        yesText: 'Remove Gallery'
      }
    }).beforeClose().subscribe((result: boolean) => {
      if (result) {
        this.galleryService.deleteGallery(this.site.id, gallery).subscribe(() => {
          this.galleries.splice(this.galleries.findIndex((gal) => {
            return gal.id === gallery.id;
          }), 1);
          this.snackBar.open('Gallery removed!', 'OK', {
            duration: 10000
          });
        });
      }
    });
  }

  orderGallery($event) {
    this.galleryService
      .updateGalleryOrder(this.site.id, this.galleries.map(gallery => parseInt(gallery.id, 10)))
      .subscribe(() => {
      }, () => {
        console.log('failed');
      });
  }

}
