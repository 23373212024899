<div class="rezty-container" [class.rezty-is-mobile]="mobileQuery.matches">
  <mat-toolbar class="rezty-toolbar" class="mat-primary">
    <button *ngIf="site" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>

    <h1 *ngIf="site">{{ site?.name }}</h1>
    <h1 *ngIf="!site">Rezty Panel</h1>
    <span class="spacer"></span>

    <mat-menu #siteSwitcher="matMenu">
      <button mat-menu-item *ngFor="let site of sites" routerLink="/sites/{{ site.id }}">
        <span>{{ site.name }}</span>
      </button>
      <button mat-menu-item routerLink="/add-site">
        <span>Create a new website</span>
      </button>

    </mat-menu>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="siteSwitcher">My Sites</button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-toolbar>
  <mat-progress-bar *ngIf="spinner" mode="indeterminate"></mat-progress-bar>

  <mat-sidenav-container class="rezty-sidenav-container">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                  [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list *ngIf="site">
        <mat-list-item>
          <a matLine routerLink="/sites/{{site.id}}/menus">Menu</a>
          <button mat-icon-button>
            <mat-icon>restaurant_menu</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item>
          <a matLine routerLink="/sites/{{site.id}}/galleries">Galleries</a>
          <button mat-icon-button>
            <mat-icon>photo_library</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item>
          <a matLine routerLink="/sites/{{site.id}}/events">Events</a>
          <button mat-icon-button>
            <mat-icon>event</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item>
          <a matLine routerLink="/sites/{{site.id}}/design">Design</a>
          <button mat-icon-button>
            <mat-icon>view_quilt</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item>
          <a matLine routerLink="/sites/{{site.id}}/settings">Settings</a>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

