import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptService } from 'ngx-script-loader';
import { Observable, Subject } from 'rxjs';
import { ReztyImageUploader } from '../image-uploader.service';
import { Site } from '../models';
import { SiteService } from '../site.service';
import { debounceTime } from 'rxjs/operators';
declare var google: any;

interface Place {
  id: string;
  main_text: string;
  secindary_text: string;
}

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss']
})
export class EditSiteComponent implements OnInit {
  placeName: string;

  constructor(
    private scriptService: ScriptService,
    private siteService: SiteService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  private autocompleteService;
  private placesService;

  places$: Observable<any>;
  places: Place[];

  private searchSubject: Subject<string> = new Subject();

  @ViewChild('siteForm') form: NgForm;

  site: Site = new Site();
  uploader: ReztyImageUploader = new ReztyImageUploader({
    path: 'spiroslounge'
  });

  ngOnInit() {
    this.siteService.getCurrentSite()
      .subscribe((site: Site) => {
        this.site = site;
      });

    this.uploader.onSuccessItem = (file, response, status) => {
      const resp = JSON.parse(response);
      this.site.logo = resp.path;
    };
  }

  public saveSite() {
    if (this.form.invalid) {
      return;
    }

    let observable;
    this.site.languages = ['en'];
    if (this.site.id) {
      observable = this.siteService.saveSite(this.site);
    } else {
      observable = this.siteService.addSite(this.site);
    }

    observable
      .subscribe((site: Site) => {
        this.router.navigate([`/sites/${site.id}`]);
      });
  }

  private placeSearch() {
    window['_callback'] = () => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.placesService = new google.maps.places.PlacesService();
    };

    this.scriptService
      .loadScript('https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyA1yXp17Jm_hSXyh8tbfxDrFobqDFXdCAA&callback=_callback')
      .subscribe(() => {

      });

    this.searchSubject.pipe(debounceTime(300)).subscribe(input => {
      this.autocompleteService.getPlacePredictions({
        input: input,
        types: ['establishment']
      }, (data) => {
        if (!data) {
          this.places = [];
        } else {
          this.places = data.map(place => {
            return {
              id: place.place_id,
              main_text: place.structured_formatting.main_text,
              secondary_text: place.structured_formatting.secondary_text
            };
          });
        }
      });
    });
  }

  search(input) {
    this.searchSubject.next(input);
  }

  placeDetail(event: MatAutocompleteSelectedEvent) {
    this.placesService.getDetails({
      placeId: event.option.id
    }, (data) => {
      console.log(data);
    });
  }
}
