<h2 mat-dialog-title *ngIf="data.slide">Edit Slide</h2>
<h2 mat-dialog-title *ngIf="!data.slide">Add Slide</h2>

<mat-dialog-content>
  <app-slide-edit [slideId]="data.slide" [siteId]="data.siteId" #editSlide (save)="slideSaved($event)"></app-slide-edit>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button color="primary" [disabled]="editSlide.form.invalid" (click)="editSlide.form.ngSubmit.emit()">Save</button>
</mat-dialog-actions>
