<form class="form-container" #frm="ngForm" (ngSubmit)="saveEvent()">
  <mat-form-field>
    <input matInput placeholder="Event title" name="title" [(ngModel)]="event.title.en" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Event headline" name="headline" [(ngModel)]="event.headline.en">
  </mat-form-field>

  <label class="mat-button mat-raised-button">
    <span>Add Image</span>
    <input type="file" ng2FileSelect [uploader]="uploader" multiple accept="image/*">
  </label>

  <ul class="ngx-dnd-container" ngxDroppable [model]="event.images">
    <li class="ngx-dnd-item" *ngFor="let item of event.images" ngxDraggable [model]="item">
      <mat-icon (click)="removeImage(item)">cancel</mat-icon>
      <img ngxDragHandle [synapsPics]="item" width="100" height="100" crop="fill">
    </li>
    <li *ngFor="let upload of uploader.queue">
      <mat-spinner [value]="upload.progress"></mat-spinner>
    </li>
  </ul>
</form>
