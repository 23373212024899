/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin-home.component";
import * as i3 from "@angular/router";
var styles_AdminHomeComponent = [i0.styles];
var RenderType_AdminHomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminHomeComponent, data: {} });
export { RenderType_AdminHomeComponent as RenderType_AdminHomeComponent };
export function View_AdminHomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" admin-home works!\n"]))], null, null); }
export function View_AdminHomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-home", [], null, null, null, View_AdminHomeComponent_0, RenderType_AdminHomeComponent)), i1.ɵdid(1, 114688, null, 0, i2.AdminHomeComponent, [i3.ActivatedRoute, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminHomeComponentNgFactory = i1.ɵccf("app-admin-home", i2.AdminHomeComponent, View_AdminHomeComponent_Host_0, {}, {}, []);
export { AdminHomeComponentNgFactory as AdminHomeComponentNgFactory };
