import { Component } from '@angular/core';

// class User {
//   email: string;
//   password: string;
// }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  // newUser: User = new User();
  // loginUser: User = new User();


  // register() {
  //   this.afAuth.auth.createUserWithEmailAndPassword(this.newUser.email, this.newUser.password).then((user) => {
  //     console.log(user);
  //   }).catch(function(error) {
  //     console.log(error);
  //   });
  // }
  // login() {
  //   this.afAuth.auth.signInWithEmailAndPassword(this.loginUser.email, this.loginUser.password).then((user) => {
  //     console.log(user);
  //   }).catch(function(error) {
  //     console.log(error);
  //   });

  // }
}
