<mat-card>
  <mat-card-header>
    <!--div mat-card-avatar class="example-header-image"></div-->
    <mat-card-title>Verify your email address</mat-card-title>
    <mat-card-subtitle>Your email address: {{ user.email }}</mat-card-subtitle>
  </mat-card-header>
  <!--img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"-->
  <mat-card-content>
    <p>It seems you didn't verify your email address yet. Please verify your e-mail address by clicking link that we sent you. If you didn't get that mail, please click button below.</p>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="!sent" mat-button color="primary" (click)="sendConfirmation()">Send Verification Email</button>
    <a *ngIf="sent" mat-button>Email sent!</a>
  </mat-card-actions>
</mat-card>
