import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../site.service';
import { Site } from '../models';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

interface Integrations {
  twitter?: string;
  facebook?: string;
  instagram?: string;
  opentable?: string;
  foursquare?: string;
  googleplace?: string;
}

@Component({
  selector: 'app-site-integrations',
  templateUrl: './site-integrations.component.html',
  styleUrls: ['./site-integrations.component.scss']
})
export class SiteIntegrationsComponent implements OnInit {
  site: Site;
  t = {};

  @ViewChild('integrationsForm') form: NgForm;
  constructor(
    private siteService: SiteService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.siteService.getCurrentSite()
      .subscribe((site: Site) => {
        this.site = site;
      });
  }

  saveIntegrations() {
    if (this.form.valid) {
      this.siteService.saveIntegrations(this.site)
        .subscribe((savedSite: Site) => {
          this.snackBar.open('Settings are saved!', 'OK', {
            duration: 5000
          });
        });
    }
  }

}
