import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Site } from '../models';

@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.scss']
})
export class SiteDashboardComponent implements OnInit {
  site: Site;
  constructor(private route: ActivatedRoute) { }
  cards = [
    {
      title: 'Card 1',
      cols: 2,
      rows: 1
    },
    {
      title: 'Card 2',
      cols: 1,
      rows: 1
    },
    {
      title: 'Card 3',
      cols: 1,
      rows: 2
    },
    {
      title: 'Card 4',
      cols: 1,
      rows: 1
    }
  ];
  ngOnInit() {
    this.route.data
      .subscribe((data: {site: Site}) => {
        this.site = data.site;
      });
  }

}
