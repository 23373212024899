import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { FirebaseUISignInSuccess } from 'firebaseui-angular';
import { User } from 'firebase/app';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  loggedIn = false;
  user: User;

  constructor(private afAuth: AngularFireAuth, private router: Router, private routeParams: ActivatedRoute) {
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccess) {
    const redirectUrl = sessionStorage.getItem('redirectTo') || '';
    sessionStorage.removeItem('redirectTo');
    this.router.navigate([redirectUrl]);
  }

  ngOnInit() {
    this.routeParams.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('mode') === 'verifyEmail') {
        this.afAuth.auth.applyActionCode(queryParams.get('oobCode')).then(() => {
          this.router.navigate(['/']);
        }).catch(() => {
          console.log('Invalid key');
        });
      }
    });
  }

  ngOnDestroy() {

  }

}
