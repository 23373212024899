import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivateChild {
  constructor(public afAuth: AngularFireAuth, private router: Router) {}
  canActivateChild(): Observable<boolean> {
    return this.afAuth.authState
      .pipe(switchMap((user) => {
        if (!user) {
          sessionStorage.setItem('redirectTo', location.pathname);
          this.router.navigate(['login']);
        }
        return of(!!user);
      }));
  }
}
