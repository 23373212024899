import { Component, OnInit, Input, EventEmitter, ViewChild, Output, OnChanges, SimpleChanges } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { Gallery, GalleryItem } from '../models';
import { SynapsPicsUtils } from 'ngx-synaps-pics';
import { environment } from '../../environments/environment';
import { NgForm } from '@angular/forms';
import { ReztyImageUploader } from '../image-uploader.service';

@Component({
  selector: 'app-edit-gallery',
  templateUrl: './edit-gallery.component.html',
  styleUrls: ['./edit-gallery.component.scss']
})
export class EditGalleryComponent implements OnInit, OnChanges {
  @Input() galleryId: string;
  @Input() siteId: string;
  @Output() save = new EventEmitter<Gallery>();
  @Output() saveStart = new EventEmitter<Gallery>();
  @ViewChild('frm') public form: NgForm;

  gallery: Gallery;
  uploader: ReztyImageUploader = new ReztyImageUploader({
    path: 'spiroslounge/galleries'
  });

  constructor(
    private galleryService: GalleryService,
    private synapsPicsUtils: SynapsPicsUtils
  ) { }

  ngOnInit() {
    this.gallery = new Gallery();
    this.gallery.items = [];

    this.uploader.onSuccessItem = (file, response, status) => {
      const resp = JSON.parse(response);
      this.gallery.items.push({
        src: resp.path
      } as GalleryItem);
    };
  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.siteId && this.galleryId) {
      this.galleryService.getGallery(this.siteId, this.galleryId).subscribe((gallery: Gallery) => {
        // gallery.items = gallery.items.map((item) => {
        //   item.src = this.synapsPicsUtils.getImageUrl({path: item.src});
        //   return item;
        // });

        this.gallery = gallery;
      });
    }
  }

  saveGallery() {
    this.galleryService.saveGallery(this.siteId, this.gallery).subscribe((gallery: Gallery) => {
      this.save.emit(gallery);
    });
  }
}
