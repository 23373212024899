export interface Deserializable<T> {
  deserialize(input: any): T;
}

class TranslatedString implements Deserializable<TranslatedString> {
  en?: string;

  toString(): string {
    return this.en;
  }

  deserialize(input: any): TranslatedString {
    Object.assign(this, input);
    return this;
  }

  toJSON() {
    return {
      en: this.en
    };
  }
}

class Integrations {
  twitter?: string;
  facebook?: string;
  instagram?: string;
}

class Site {
  id: string;
  name: string;
  description: string;
  logo: string;
  joined: Date;
  domains: string[];
  languages: string[];
  galleries?: number[];
  locations?: number[];
  integrations?: Integrations;
  menus?: number[];
}

class GalleryItem implements Deserializable<GalleryItem> {
  src: string;

  deserialize(input: any): GalleryItem {
    return Object.assign(this, input);
  }

  toJSON() {
    return {
      src: this.src
    };
  }
}

class Gallery implements Deserializable<Gallery> {
  id: string;
  name: TranslatedString;
  items: GalleryItem[];
  tags: string[];

  constructor() {
    this.name = new TranslatedString();
  }

  deserialize(input: any): Gallery {
    Object.assign(this, input);
    this.name = new TranslatedString().deserialize(input.name);
    return this;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name && this.name.toJSON(),
      items: this.items,
      tags: this.tags
    };
  }
}

class Event implements Deserializable<Event> {
  id: string;
  title: TranslatedString;
  headline: TranslatedString;
  description: TranslatedString;
  images: GalleryItem[];
  expire_date: string;

  constructor() {
    this.title = new TranslatedString();
    this.headline = new TranslatedString();
    // this.description = new TranslatedString();
  }

  deserialize(input: any): Event {
    Object.assign(this, input);
    this.title = new TranslatedString().deserialize(input.title);
    this.headline = new TranslatedString().deserialize(input.headline);
    if (input.description) {
      this.description = new TranslatedString().deserialize(input.description);
    }
    return this;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title && this.title.toJSON(),
      headline: this.headline && this.headline.toJSON(),
      description: this.description && this.description.toJSON(),
      images: this.images,
      expire_date: this.expire_date
    };
  }
}
export { Site, Gallery, GalleryItem, Event };
