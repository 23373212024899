import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Gallery } from '../models';

@Component({
  selector: 'app-slide-edit-dialog',
  templateUrl: './slide-edit-dialog.component.html',
  styleUrls: ['./slide-edit-dialog.component.scss']
})
export class SlideEditDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SlideEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  ngOnInit() {
  }

  slideSaved(savedSlide: Gallery) {
    this.dialogRef.close(savedSlide);
  }

}
