/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/card";
import * as i8 from "ngx-synaps-pics";
import * as i9 from "@swimlane/ngx-dnd";
import * as i10 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i11 from "@angular/common";
import * as i12 from "../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i13 from "@angular/material/toolbar";
import * as i14 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "./gallery-list.component";
import * as i17 from "@angular/router";
import * as i18 from "../gallery.service";
import * as i19 from "@angular/material/dialog";
import * as i20 from "@angular/material/snack-bar";
var styles_GalleryListComponent = [i0.styles];
var RenderType_GalleryListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryListComponent, data: {} });
export { RenderType_GalleryListComponent as RenderType_GalleryListComponent };
function View_GalleryListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "no-gallery"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There is no gallery."])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGallery(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add new gallery"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_GalleryListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "mat-card-image"], ["crop", "fill"], ["height", "200"], ["mat-card-image", ""], ["width", "300"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i7.MatCardImage, [], null, null), i1.ɵdid(2, 81920, null, 0, i8.SynapsPicsDirective, [i1.ElementRef, i8.SynapsPicsUtils, i1.Renderer2], { width: [0, "width"], height: [1, "height"], crop: [2, "crop"], imageUrl: [3, "imageUrl"] }, null)], function (_ck, _v) { var currVal_0 = "300"; var currVal_1 = "200"; var currVal_2 = "fill"; var currVal_3 = _v.parent.context.$implicit.items[0].src; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_GalleryListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "gallery-item ngx-dnd-item"], ["ngxDraggable", ""]], null, [[null, "touchmove"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("touchmove" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMove($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDown() !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 475136, null, 0, i9.DraggableDirective, [i1.ElementRef, i9.DrakeStoreService, i9.DroppableDirective], { ngxDraggable: [0, "ngxDraggable"], model: [1, "model"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 19, "mat-card", [["class", "mat-card"]], null, null, null, i10.View_MatCard_0, i10.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i7.MatCard, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 7, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i10.View_MatCardHeader_0, i10.RenderType_MatCardHeader)), i1.ɵdid(5, 49152, null, 0, i7.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i7.MatCardTitle, [], null, null), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, 1, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i7.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(11, null, ["", " images"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GalleryListComponent_3)), i1.ɵdid(13, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 7, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i7.MatCardActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGallery(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Edit"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["color", "warn"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeGallery(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete"]))], function (_ck, _v) { var currVal_0 = ""; var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.items[0]; _ck(_v, 13, 0, currVal_4); var currVal_8 = "primary"; _ck(_v, 17, 0, currVal_8); var currVal_11 = "warn"; _ck(_v, 20, 0, currVal_11); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.items.length; _ck(_v, 11, 0, currVal_3); var currVal_5 = (i1.ɵnov(_v, 15).align === "end"); _ck(_v, 14, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 17).disabled || null); var currVal_7 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_6, currVal_7); var currVal_9 = (i1.ɵnov(_v, 20).disabled || null); var currVal_10 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_9, currVal_10); }); }
export function View_GalleryListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-toolbar", [["class", "mat-toolbar"], ["color", "accent"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i12.View_MatToolbar_0, i12.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i13.MatToolbar, [i1.ElementRef, i4.Platform, i11.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Galleries"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryListComponent_1)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "gallery-container ngx-dnd-container"], ["ngxDroppable", ""]], null, [[null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.orderGallery($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4407296, null, 0, i9.DroppableDirective, [i1.ElementRef, i1.Renderer2, i9.DrakeStoreService], { model: [0, "model"], ngxDroppable: [1, "ngxDroppable"] }, { drop: "drop" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryListComponent_2)), i1.ɵdid(10, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGallery(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); var currVal_3 = (_co.galleries && (_co.galleries.length == 0)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.galleries; var currVal_5 = ""; _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = _co.galleries; _ck(_v, 10, 0, currVal_6); _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_7 = (i1.ɵnov(_v, 12).disabled || null); var currVal_8 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 14).inline; _ck(_v, 13, 0, currVal_9); }); }
export function View_GalleryListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gallery-list", [], null, null, null, View_GalleryListComponent_0, RenderType_GalleryListComponent)), i1.ɵdid(1, 114688, null, 0, i16.GalleryListComponent, [i17.ActivatedRoute, i18.GalleryService, i19.MatDialog, i20.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GalleryListComponentNgFactory = i1.ɵccf("app-gallery-list", i16.GalleryListComponent, View_GalleryListComponent_Host_0, {}, {}, []);
export { GalleryListComponentNgFactory as GalleryListComponentNgFactory };
