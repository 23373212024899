import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Site } from './models';
import { SiteService } from './site.service';

@Injectable()
export class SitesResolver implements Resolve<Site[]> {
  constructor(private sitesService: SiteService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Site[]> {
    const sub = new Subject<Site[]>();

    this.sitesService.getSites()
      .subscribe(sites => {
        sub.next(sites);
        sub.complete();
      });

    return sub;
  }
}
