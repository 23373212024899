import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../site.service';
import { Site } from '../models';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

class Domain {
  domain: string;
}

@Component({
  selector: 'app-domain-settings',
  templateUrl: './domain-settings.component.html',
  styleUrls: ['./domain-settings.component.scss']
})
export class DomainSettingsComponent implements OnInit {
  serviceDomain = '.rezty.com';
  domains: Domain[];

  private site: Site;
  @ViewChild('domainForm') form: NgForm;

  constructor(private siteService: SiteService) { }

  ngOnInit() {
    this.domains = [new Domain()];
    this.siteService.getCurrentSite()
      .subscribe((site: Site) => {
        this.site = site;
        if (site.domains.length > 0) {
          this.domains = site.domains.map((dom) => {
            const domain = new Domain();
            domain.domain = dom.replace(this.serviceDomain, '');
            return domain;
          });
        }
      });
  }

  public addDomain() {
    this.domains.push(new Domain());
  }

  public removeDomain(index) {
    this.domains.splice(index, 1);
  }

  saveDomains() {
    if (this.form.invalid) {
      return;
    }

    const newDomains = this.domains.map((domain) => domain.domain);
    newDomains[0] += this.serviceDomain;

    console.log('Current domain list', this.site.domains);
    console.log('New domain list', newDomains);
    const domainsToBeRemoved = this.site.domains.filter((domain) => {
      return !newDomains.includes(domain);
    });

    console.log('Domains to be removed', domainsToBeRemoved);

    const domainsToBeAdded = newDomains.filter((domain) => {
      return !this.site.domains.includes(domain);
    });

    console.log('Domains to be added', domainsToBeAdded);

    // let urlQueue = Observable.create();

    domainsToBeAdded.forEach((domain) => {
      this.siteService.addDomain(this.site, domain)
        .subscribe((result) => {
          console.log(`${domain} added!`);
        }, () => {
          console.log(`${domain} couldnt add.`);
        });
    });

    domainsToBeRemoved.forEach((domain) => {
      this.siteService.removeDomain(this.site, domain)
        .subscribe((result) => {
          console.log(`${domain} removed!`);
        }, () => {
          console.log(`${domain} couldnt remove.`);
        });
    });
  }

}
