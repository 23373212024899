/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slide-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/card";
import * as i8 from "ngx-synaps-pics";
import * as i9 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i10 from "@angular/common";
import * as i11 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "./slide-list.component";
import * as i14 from "@angular/router";
import * as i15 from "../gallery.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "@angular/material/snack-bar";
import * as i18 from "../site.service";
var styles_SlideListComponent = [i0.styles];
var RenderType_SlideListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlideListComponent, data: {} });
export { RenderType_SlideListComponent as RenderType_SlideListComponent };
function View_SlideListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "no-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There is no slide."])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSlide(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add new slide"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_SlideListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "mat-card-image"], ["crop", "fill"], ["height", "200"], ["mat-card-image", ""], ["width", "300"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i7.MatCardImage, [], null, null), i1.ɵdid(2, 81920, null, 0, i8.SynapsPicsDirective, [i1.ElementRef, i8.SynapsPicsUtils, i1.Renderer2], { width: [0, "width"], height: [1, "height"], crop: [2, "crop"], imageUrl: [3, "imageUrl"] }, null)], function (_ck, _v) { var currVal_0 = "300"; var currVal_1 = "200"; var currVal_2 = "fill"; var currVal_3 = _v.parent.context.$implicit.items[0].src; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SlideListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "slide-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "mat-card", [["class", "mat-card"]], null, null, null, i9.View_MatCard_0, i9.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i7.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i9.View_MatCardHeader_0, i9.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i7.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i7.MatCardTitle, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 1, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i7.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(10, null, ["", " images"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SlideListComponent_3)), i1.ɵdid(12, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 7, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i7.MatCardActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSlide(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Edit"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["color", "warn"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeSlide(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete"]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.items[0]; _ck(_v, 12, 0, currVal_2); var currVal_6 = "primary"; _ck(_v, 16, 0, currVal_6); var currVal_9 = "warn"; _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_0); var currVal_1 = _v.context.$implicit.items.length; _ck(_v, 10, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 14).align === "end"); _ck(_v, 13, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 16).disabled || null); var currVal_5 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_7 = (i1.ɵnov(_v, 19).disabled || null); var currVal_8 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_7, currVal_8); }); }
export function View_SlideListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SlideListComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "slide-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SlideListComponent_2)), i1.ɵdid(4, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSlide(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.slides && (_co.slides.length == 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.slides; _ck(_v, 4, 0, currVal_1); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 8).inline; _ck(_v, 7, 0, currVal_4); }); }
export function View_SlideListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slide-list", [], null, null, null, View_SlideListComponent_0, RenderType_SlideListComponent)), i1.ɵdid(1, 114688, null, 0, i13.SlideListComponent, [i14.ActivatedRoute, i15.GalleryService, i16.MatDialog, i17.MatSnackBar, i18.SiteService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SlideListComponentNgFactory = i1.ɵccf("app-slide-list", i13.SlideListComponent, View_SlideListComponent_Host_0, {}, {}, []);
export { SlideListComponentNgFactory as SlideListComponentNgFactory };
