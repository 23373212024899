<h2 mat-dialog-title *ngIf="data.event">Edit Event</h2>
<h2 mat-dialog-title *ngIf="!data.event">Add Event</h2>

<mat-dialog-content>
  <app-event-edit [eventId]="data.event" [siteId]="data.siteId" #editEvent (save)="onSave($event)"></app-event-edit>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button color="primary" (click)="editEvent.form.ngSubmit.emit()" [disabled]="editEvent.form.invalid">Save</button>
</mat-dialog-actions>
