import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteService } from './site.service';
import { Site } from './models';
import { Subject } from 'rxjs';

@Injectable()
export class SiteResolver implements Resolve<Site> {
  constructor(private sitesService: SiteService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Site> {
    const sub = new Subject<Site>();

    this.sitesService.getSite(route.params.site)
      .subscribe(site => {
        this.sitesService.setCurrentSite(site);
        sub.next(site);
        sub.complete();
      });

    return sub;

  }
}
