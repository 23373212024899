/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-synaps-pics";
import * as i3 from "../../../node_modules/firebaseui-angular/firebaseui-angular.ngfactory";
import * as i4 from "firebaseui-angular";
import * as i5 from "@angular/fire/auth";
import * as i6 from "./auth.component";
import * as i7 from "@angular/router";
var styles_AuthComponent = [i0.styles];
var RenderType_AuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["as-background", ""]], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.SynapsPicsDirective, [i1.ElementRef, i2.SynapsPicsUtils, i1.Renderer2], { imageUrl: [0, "imageUrl"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "firebase-ui", [], null, [[null, "signInSuccessWithAuthResult"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("signInSuccessWithAuthResult" === en)) {
        var pd_0 = (_co.successCallback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FirebaseuiAngularLibraryComponent_0, i3.RenderType_FirebaseuiAngularLibraryComponent)), i1.ɵdid(3, 245760, null, 0, i4.FirebaseuiAngularLibraryComponent, [i5.AngularFireAuth, "firebaseUIAuthConfig", i4.FirebaseuiAngularLibraryService], null, { signInSuccessWithAuthResultCallback: "signInSuccessWithAuthResult" })], function (_ck, _v) { var currVal_0 = "/rezty/logos/text.png"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i1.ɵdid(1, 245760, null, 0, i6.AuthComponent, [i5.AngularFireAuth, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i1.ɵccf("app-auth", i6.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
