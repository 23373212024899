import * as tslib_1 from "tslib";
import { Gallery } from './models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
var GalleryService = /** @class */ (function (_super) {
    tslib_1.__extends(GalleryService, _super);
    function GalleryService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GalleryService.prototype.getSiteGalleries = function (site) {
        return this.list("sites/" + site + "/galleries")
            .pipe(map(function (galleries) { return galleries.map(function (gallery) { return new Gallery().deserialize(gallery); }); }));
    };
    GalleryService.prototype.getGallery = function (site, galleryId) {
        return this.get("sites/" + site + "/galleries/" + galleryId)
            .pipe(map(function (gallery) { return new Gallery().deserialize(gallery); }));
    };
    GalleryService.prototype.updateGalleryOrder = function (site, galleryIds) {
        return this.put("sites/" + site + "/galleries", galleryIds);
    };
    GalleryService.prototype.saveGallery = function (siteId, gallery) {
        if (gallery.id) {
            return this.put("sites/" + siteId + "/galleries/" + gallery.id, gallery)
                .pipe(map(function (response) { return new Gallery().deserialize(response); }));
        }
        else {
            return this.post("sites/" + siteId + "/galleries", gallery)
                .pipe(map(function (response) { return new Gallery().deserialize(response); }));
        }
    };
    GalleryService.prototype.deleteGallery = function (siteId, gallery) {
        return this.delete("sites/" + siteId + "/galleries/" + gallery.id);
    };
    return GalleryService;
}(ApiService));
export { GalleryService };
