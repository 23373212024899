import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { Event } from '../models';
import { environment } from '../../environments/environment';
import { EventService } from '../event.service';
import { SynapsPicsUtils } from 'ngx-synaps-pics';
import { Form, NgForm } from '@angular/forms';
import { ReztyImageUploader } from '../image-uploader.service';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit, OnChanges {
  @Input() eventId: string;

  @Input() siteId: string;
  @Output() save = new EventEmitter<Event>();
  @Output() saveStart = new EventEmitter<Event>();
  @ViewChild('frm') public form: NgForm;

  event: Event;
  uploader: ReztyImageUploader = new ReztyImageUploader({
    path: 'spiroslounge/events'
  });

  constructor(
    private eventService: EventService,
    private synapsPicsUtils: SynapsPicsUtils
  ) {
    this.event = new Event();
    this.event.images = [];
  }

  ngOnInit() {

    this.uploader.onSuccessItem = (file, response, status) => {
      const resp = JSON.parse(response);
      this.event.images.push(resp.path);
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.siteId && this.eventId) {
      this.eventService.getEvent(this.siteId, this.eventId).subscribe((event: Event) => {
        this.event = event;
      });
    }
  }

  removeImage(item) {
    this.event.images = this.event.images.filter((image) => {
      return image !== item;
    });
  }

  saveEvent() {
    console.log('save event');
    this.saveStart.emit(this.event);

    this.eventService.saveEvent(this.siteId, this.event).subscribe((event: Event) => {
      this.save.emit(event);
    }, () => {
      this.save.emit();
    });
  }

}
