/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-gallery-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../edit-gallery/edit-gallery.component.ngfactory";
import * as i5 from "../edit-gallery/edit-gallery.component";
import * as i6 from "../gallery.service";
import * as i7 from "ngx-synaps-pics";
import * as i8 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./edit-gallery-dialog.component";
var styles_EditGalleryDialogComponent = [i0.styles];
var RenderType_EditGalleryDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditGalleryDialogComponent, data: {} });
export { RenderType_EditGalleryDialogComponent as RenderType_EditGalleryDialogComponent };
function View_EditGalleryDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Edit Gallery"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_EditGalleryDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Add Gallery"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
export function View_EditGalleryDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditGalleryDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditGalleryDialogComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-edit-gallery", [], null, [[null, "save"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.gallerySaved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_EditGalleryComponent_0, i4.RenderType_EditGalleryComponent)), i1.ɵdid(7, 638976, [["editGallery", 4]], 0, i5.EditGalleryComponent, [i6.GalleryService, i7.SynapsPicsUtils], { galleryId: [0, "galleryId"], siteId: [1, "siteId"] }, { save: "save" }), (_l()(), i1.ɵeld(8, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["mat-dialog-close", ""], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(12, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).form.ngSubmit.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.gallery; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.data.gallery; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.data.gallery; var currVal_3 = _co.data.siteId; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_7 = ""; _ck(_v, 12, 0, currVal_7); var currVal_10 = i1.ɵnov(_v, 7).form.invalid; var currVal_11 = "primary"; _ck(_v, 15, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_6 = i1.ɵnov(_v, 12).ariaLabel; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 15).disabled || null); var currVal_9 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_8, currVal_9); }); }
export function View_EditGalleryDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-gallery-dialog", [], null, null, null, View_EditGalleryDialogComponent_0, RenderType_EditGalleryDialogComponent)), i1.ɵdid(1, 114688, null, 0, i13.EditGalleryDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditGalleryDialogComponentNgFactory = i1.ɵccf("app-edit-gallery-dialog", i13.EditGalleryDialogComponent, View_EditGalleryDialogComponent_Host_0, {}, {}, []);
export { EditGalleryDialogComponentNgFactory as EditGalleryDialogComponentNgFactory };
