<mat-toolbar color="accent">
  <span>Events</span>
</mat-toolbar>

<div *ngIf="events.length == 0">
  <p>There is no events</p>
</div>

<div class="event-container ngx-dnd-container" ngxDroppable [model]="events" (drop)="orderEvent($event)">
  <div class="event-item ngx-dnd-item" ngxDraggable [model]="event" *ngFor="let event of events">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ event.title }}</mat-card-title>
        <mat-card-subtitle>{{ event.headline }}</mat-card-subtitle>
      </mat-card-header>
      <div mat-card-image [synapsPics]="event.images[0]" width="300" height="200" crop="fill" as-background></div>
      <mat-card-actions>
        <button mat-button color="primary" (click)="addEvent(event.id)">Edit</button>
        <button mat-button color="warn" (click)="deleteEvent(event)">Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<button mat-fab color="primary" (click)="addEvent(null)">
  <mat-icon>add</mat-icon>
</button>
