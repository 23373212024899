/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./site-users.component";
var styles_SiteUsersComponent = [i0.styles];
var RenderType_SiteUsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteUsersComponent, data: {} });
export { RenderType_SiteUsersComponent as RenderType_SiteUsersComponent };
export function View_SiteUsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" site-users works!\n"]))], null, null); }
export function View_SiteUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-users", [], null, null, null, View_SiteUsersComponent_0, RenderType_SiteUsersComponent)), i1.ɵdid(1, 114688, null, 0, i2.SiteUsersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteUsersComponentNgFactory = i1.ɵccf("app-site-users", i2.SiteUsersComponent, View_SiteUsersComponent_Host_0, {}, {}, []);
export { SiteUsersComponentNgFactory as SiteUsersComponentNgFactory };
