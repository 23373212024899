import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Event } from './models';

@Injectable()
export class EventService extends ApiService<Event> {

  getSiteEvents(site: string): Observable<Event[]> {
    return this.list(`sites/${site}/events`)
      .pipe(map((events: Event[]) => events.map(event => new Event().deserialize(event))));
  }

  getEvent(siteId: string, eventId: string): Observable<Event> {
    return this.get(`sites/${siteId}/events/${eventId}`)
      .pipe(map(event => new Event().deserialize(event)));
  }

  updateEventOrder(site: string, eventIds: number[]): Observable<number[]> {
    return this.put(`sites/${site}/events`, eventIds);
  }

  saveEvent(siteId: string, event: Event): Observable<Event> {
    if (event.id) {
      return this.put(`sites/${siteId}/events/${event.id}`, event)
        .pipe(map(newEvent => new Event().deserialize(newEvent)));
    } else {
      return this.post(`sites/${siteId}/events`, event)
        .pipe(map(newEvent => new Event().deserialize(newEvent)));
    }
  }

  deleteEvent(siteId: string, event: Event): Observable<any> {
    return this.delete(`sites/${siteId}/events/${event.id}`);
  }
}
