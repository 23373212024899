export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyArV8O3u58w0a-tcLPT-biiTjY6g9yDH0M',
    authDomain: 'synaps-resto.firebaseapp.com',
    databaseURL: 'https://synaps-resto.firebaseio.com',
    projectId: 'synaps-resto',
    storageBucket: 'synaps-resto.appspot.com',
    messagingSenderId: '617102830739'
  }
};
