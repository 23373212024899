import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GalleryService } from '../gallery.service';
import { Gallery, Site } from '../models';
import { SiteService } from '../site.service';
import { SlideEditDialogComponent } from '../slide-edit-dialog/slide-edit-dialog.component';

@Component({
  selector: 'app-slide-list',
  templateUrl: './slide-list.component.html',
  styleUrls: ['./slide-list.component.scss']
})
export class SlideListComponent implements OnInit {
  slides: Gallery[];

  private site: Site;

  constructor(
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private siteService: SiteService
  ) { }

  ngOnInit() {
    this.siteService.getCurrentSite().pipe(
      tap((site: Site) => this.site = site),
      switchMap((site: Site) => this.galleryService.getSiteGalleries(site.id)),
      map((gallery) => gallery.filter((gal) => gal.tags.includes('slide')))
    )
      .subscribe((slides: Gallery[]) => {
        this.slides = slides;
      });

  }

  addSlide(slideId: string) {
    this.dialog.open(SlideEditDialogComponent, {
      disableClose: true,
      minWidth: '300px',
      maxWidth: '90%',
      maxHeight: '90%',
      data: {
        slide: slideId,
        siteId: this.site.id
      }
    }).afterClosed().subscribe((savedSlide: Gallery) => {
      if (!savedSlide) {
        return;
      }
      if (slideId) {
        this.slides[this.slides.findIndex(g => g.id === slideId)] = savedSlide;
      } else {
        this.slides.push(savedSlide);
      }
    });
  }

  removeSlide(slide: Gallery) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'Are you sure to remove this slide? You will loose all of it\'s images.',
        yesText: 'Remove Slide'
      }
    }).beforeClose().subscribe((result: boolean) => {
      if (result) {
        this.galleryService.deleteGallery(this.site.id, slide).subscribe(() => {
          this.slides.splice(this.slides.findIndex((gal) => {
            return gal.id === slide.id;
          }), 1);
          this.snackBar.open('Slide removed!', 'OK', {
            duration: 10000
          });
        });
      }
    });
  }

}
