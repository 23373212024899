<form #domainForm="ngForm" (submit)="saveDomains()">
  <mat-form-field hintLabel="Max 50 characters">
    <input matInput #input="ngModel" [(ngModel)]="domains[0].domain" pattern="[0-9a-zA-Z\-]*" name="domain" maxlength="50" placeholder="Your subdomain" required>
    <span matSuffix>{{ serviceDomain }}</span>
    <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
    <mat-error *ngIf="input.invalid">Your subdomain can only contains chars, numbers and dash. Examples: your-company or yourcompany</mat-error>
  </mat-form-field>

  <mat-form-field *ngFor="let domain of domains | slice:1;let i = index">
    <input matInput [(ngModel)]="domain.domain" name="domain-{{ i }}" maxlength="65" pattern="^[a-zA-Z0-9-\.]+\.[a-zA-Z]{2,}$" placeholder="Your custom subdomain">
    <mat-icon matSuffix (click)="removeDomain(i+1)">delete</mat-icon>
    <mat-error>Your domain seems wrong. Please check.</mat-error>
  </mat-form-field>

  <a mat-button (click)="addDomain()" *ngIf="domains.length < 5">Add custom domain</a>

  <button mat-button>Save</button>
</form>
