import { FileUploader } from 'ng2-file-upload';
import { environment } from '../environments/environment';

export interface FileUploadConfig {
  path: string;
}

export class ReztyImageUploader extends FileUploader {
  constructor(config: FileUploadConfig) {
    super({
      url: environment.production ? 'https://api.synaps.pics/upload' : '/upload',
      autoUpload: true,
      maxFileSize: 1024 * 1024 * 10,
      authToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlenR5IiwicHJlc2VydmVGaWx' +
        'lTmFtZSI6ZmFsc2UsImlhdCI6MTUzNjU5NDM2OX0.LpYu7bqNJsMSiAGRhrCzmIeII5Pt5FkiCprPdgwqmZk',
      itemAlias: 'image',
      removeAfterUpload: true,
      additionalParameter: {
        path: config.path
      }
    });
  }

  onAfterAddingFile(file: any) {
    file.withCredentials = false;
  }
}
