import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { User } from 'firebase/app';
import * as Raven from 'raven-js';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private auth: AngularFireAuth) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> {
    const sub = new Subject<User>();
    this.auth.authState.subscribe((user) => {
      Raven.setUserContext({
        email: user.email,
        id: user.uid
      });
      sub.next(user);
      sub.complete();
    });
    return sub;
  }
}
