
<form class="integrations-edit-form" #integrationsForm="ngForm" *ngIf="site" (submit)="saveIntegrations()">
  <h3 class="mat-title">Social Media Accounts</h3>
  <mat-form-field>
    <input matInput [(ngModel)]="site.integrations.instagram" name="instagram" placeholder="Your Instagram Account">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="site.integrations.facebook" name="facebook" placeholder="Your Facebook Page">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="site.integrations.twitter" name="twitter" placeholder="Your Twitter Account">
  </mat-form-field>

  <mat-divider></mat-divider>

  <h3 class="mat-title">Platform Integrations</h3>

  <mat-checkbox [(ngModel)]="t.sp" name="sp">Single Platform</mat-checkbox>
  <mat-form-field *ngIf="t.sp">
    <input matInput [(ngModel)]="site.integrations.singleplatform" name="singleplatform" placeholder="Your Single Platform location id">
  </mat-form-field>

  <mat-checkbox>OpenTable</mat-checkbox>
  <mat-checkbox>FourSquare</mat-checkbox>


  <mat-divider></mat-divider>

  <div>
    <button type="submit" mat-raised-button [disabled]="integrationsForm.invalid" color="primary">Save</button>
  </div>

</form>
