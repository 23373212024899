import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';

export abstract class ApiService<T> {
  private baseUrl = '/api';

  constructor(private http: HttpClient) {
    if (environment.production) {
      this.baseUrl = 'https://api.rezty.com';
    }
  }

  private request(method: string, path: string, body?: any) {
    return this.http.request(method, `${this.baseUrl}/${path}`, {
      body: body
    });
  }

  protected get(path: string): Observable<T> {
    return this.request('get', path)
      .pipe(map(response => response as T));
  }

  protected list(path: string): Observable<T[]> {
    return this.request('get', path)
      .pipe(map(response => response as T[]));
  }

  protected post(path: string, body: any): Observable<any> {
    return this.request('post', path, body);
  }

  protected put(path: string, body: any): Observable<any> {
    return this.request('put', path, body);
  }

  protected patch(path: string, body: any): Observable<any> {
    return this.request('patch', path, body);
  }

  protected delete(path: string): Observable<any> {
    return this.request('delete', path);
  }
}
