import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gallery } from './models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class GalleryService extends ApiService<Gallery> {

  getSiteGalleries(site: string): Observable<Gallery[]> {
    return this.list(`sites/${site}/galleries`)
      .pipe(map((galleries: Gallery[]) => galleries.map(gallery => new Gallery().deserialize(gallery))));
  }

  getGallery(site: string, galleryId: string): Observable<Gallery> {
    return this.get(`sites/${site}/galleries/${galleryId}`)
      .pipe(map(gallery => new Gallery().deserialize(gallery)));
  }

  updateGalleryOrder(site: string, galleryIds: number[]): Observable<number[]> {
    return this.put(`sites/${site}/galleries`, galleryIds);
  }

  saveGallery(siteId: string, gallery: Gallery): Observable<Gallery> {
    if (gallery.id) {
      return this.put(`sites/${siteId}/galleries/${gallery.id}`, gallery)
        .pipe(map(response => new Gallery().deserialize(response)));
    } else {
      return this.post(`sites/${siteId}/galleries`, gallery)
        .pipe(map(response => new Gallery().deserialize(response)));
    }
  }

  deleteGallery(siteId: string, gallery: Gallery): Observable<any> {
    return this.delete(`sites/${siteId}/galleries/${gallery.id}`);
  }
}
