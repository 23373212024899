<mat-toolbar color="accent">
  <span>Design</span>
</mat-toolbar>
<!-- <nav mat-tab-nav-bar color="primary">
  <a mat-tab-link routerLink="." routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">General</a>
  <a mat-tab-link routerLink="integrations" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">Integrations</a>
  <a mat-tab-link routerLink="domains" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">Domains</a>
  <a mat-tab-link routerLink="users" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">Users</a>
</nav> -->

<nav mat-tab-nav-bar>
  <mat-tab-group backgroundColor="accent">
    <mat-tab>
      <ng-template mat-tab-label>
        <a mat-tab-link routerLink="." routerLinkActive>Theme</a>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <a mat-tab-link routerLink="slides" routerLinkActive>Slides</a>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</nav>

<router-outlet></router-outlet>
