<div *ngIf="slides && slides.length == 0" class="no-slide">
  <p class="mat-title">There is no slide.</p>
  <button mat-button color="primary" (click)="addSlide(null)">Add new slide</button>
</div>

<div class="slide-container">
  <div class="slide-item" *ngFor="let slide of slides">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ slide.name }}</mat-card-title>
        <mat-card-subtitle>{{ slide.items.length }} images</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image *ngIf="slide.items[0]" [synapsPics]="slide.items[0].src" width="300" height="200" crop="fill">
      <mat-card-actions>
        <button mat-button color="primary" (click)="addSlide(slide.id)">Edit</button>
        <button mat-button color="warn" (click)="removeSlide(slide)">Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<button mat-fab (click)="addSlide(null)">
  <mat-icon>add</mat-icon>
</button>
