import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-users',
  templateUrl: './site-users.component.html',
  styleUrls: ['./site-users.component.scss']
})
export class SiteUsersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
