import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '../models';
import { User } from 'firebase/app';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: {sites: Site[], user: User}) => {
      if (!data.user.emailVerified) {
        this.router.navigate(['verify']);
      } else if (data.sites.length === 0) {
        this.router.navigate(['add-site']);
      } else {
        const defaultSiteId = localStorage.getItem('site_id') || data.sites[0].id;
        this.router.navigate([`sites/${defaultSiteId}`]);
      }
    });
  }

}
