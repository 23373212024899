import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AngularFireAuth) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.idToken
      .pipe(switchMap((idToken) => {
        if (idToken) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${idToken}`
            }
          });
        }
        return next.handle(request);
      }));
  }
}
