import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Site } from '../models';
import { User } from 'firebase/app';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  user: User;
  sent = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe((data: {sites: Site[], user: User}) => {
      this.user = data.user;
    });
  }

  sendConfirmation() {
    this.user.sendEmailVerification().then(() => {
      this.sent = true;
    });
  }

}
