<!-- <div class="search-preview">
  <h3 class="mat-title">Search result preview</h3>
  <app-google-search-preview [title]="site.name" [description]="site.description" [url]="site.domains[site.domains.length - 1]"></app-google-search-preview>
</div> -->

<form class="site-edit-form" #siteForm="ngForm" (submit)="saveSite()">
  <!-- <mat-form-field hintLabel="Max 50 characters">
    <input type="text" placeholder="Please write your name of place" matInput [(ngModel)]="placeName" name="place" #name (keyup)="search(name.value)" [matAutocomplete]="auto">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="placeDetail($event)">
    <mat-option *ngFor="let place of places" [value]="place">
      {{ place.main_text }}<br>
      {{ place.seconday_text }}
    </mat-option>
  </mat-autocomplete> -->

  <mat-form-field hintLabel="Max 50 characters">
    <input matInput #input [(ngModel)]="site.name" name="name" maxlength="50" placeholder="Name of your place" required>
    <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
  </mat-form-field>

  <mat-form-field hintLabel="Describe your company">
    <textarea matInput #descriptionInput matTextareaAutosize [(ngModel)]="site.description" name="description" maxlength="250" required></textarea>
    <mat-hint align="end">{{descriptionInput.value?.length || 0}}/250</mat-hint>
  </mat-form-field>

  <mat-divider></mat-divider>

  <h3 class="mat-title">Your Logo</h3>

  <div>
    <div class="logo-container">
      <img [synapsPics]="site.logo" width="300" height="100" crop="limit">
    </div>
    <label class="mat-raised-button">
      <span>Change logo</span>
      <input type="file" ng2FileSelect [uploader]="uploader" accept="image/*">
    </label>
  </div>

  <mat-divider></mat-divider>

  <div>
    <button type="submit" mat-raised-button color="primary">Save</button>
  </div>
</form>
