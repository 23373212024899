<form class="form-container" #frm="ngForm" (ngSubmit)="saveSlide()">
  <mat-form-field>
    <input matInput placeholder="Slide Name" [(ngModel)]="slide.name.en" name="name">
  </mat-form-field>

  <label class="mat-button mat-raised-button">
    <span>Add image</span>
    <input type="file" ng2FileSelect [uploader]="uploader" multiple accept="image/*">
  </label>

  <ul class="ngx-dnd-container" ngxDroppable [model]="slide.items">
    <li class="ngx-dnd-item" *ngFor="let item of slide.items" ngxDraggable>
      <img [synapsPics]="item.src" width="100" height="100" crop="fill">
    </li>
    <li *ngFor="let upload of uploader.queue">
      <mat-spinner [value]="upload.progress"></mat-spinner>
    </li>
  </ul>
</form>
