/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./theme-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./theme-selector.component";
var styles_ThemeSelectorComponent = [i0.styles];
var RenderType_ThemeSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThemeSelectorComponent, data: {} });
export { RenderType_ThemeSelectorComponent as RenderType_ThemeSelectorComponent };
export function View_ThemeSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" theme-selector works!\n"]))], null, null); }
export function View_ThemeSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-theme-selector", [], null, null, null, View_ThemeSelectorComponent_0, RenderType_ThemeSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i2.ThemeSelectorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThemeSelectorComponentNgFactory = i1.ɵccf("app-theme-selector", i2.ThemeSelectorComponent, View_ThemeSelectorComponent_Host_0, {}, {}, []);
export { ThemeSelectorComponentNgFactory as ThemeSelectorComponentNgFactory };
