<mat-toolbar color="accent">
  <span>Galleries</span>
</mat-toolbar>

<div *ngIf="galleries && galleries.length == 0" class="no-gallery">
  <p class="mat-title">There is no gallery.</p>
  <button mat-button color="primary" (click)="addGallery(null)">Add new gallery</button>
</div>

<div class="gallery-container ngx-dnd-container" ngxDroppable [model]="galleries" (drop)="orderGallery($event)">
  <div class="gallery-item ngx-dnd-item" ngxDraggable [model]="gallery" *ngFor="let gallery of galleries">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ gallery.name }}</mat-card-title>
        <mat-card-subtitle>{{ gallery.items.length }} images</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image *ngIf="gallery.items[0]" [synapsPics]="gallery.items[0].src" width="300" height="200" crop="fill">
      <mat-card-actions>
        <button mat-button color="primary" (click)="addGallery(gallery.id)">Edit</button>
        <button mat-button color="warn" (click)="removeGallery(gallery)">Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<button mat-fab (click)="addGallery(null)">
  <mat-icon>add</mat-icon>
</button>
