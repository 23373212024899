import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Event } from './models';
var EventService = /** @class */ (function (_super) {
    tslib_1.__extends(EventService, _super);
    function EventService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EventService.prototype.getSiteEvents = function (site) {
        return this.list("sites/" + site + "/events")
            .pipe(map(function (events) { return events.map(function (event) { return new Event().deserialize(event); }); }));
    };
    EventService.prototype.getEvent = function (siteId, eventId) {
        return this.get("sites/" + siteId + "/events/" + eventId)
            .pipe(map(function (event) { return new Event().deserialize(event); }));
    };
    EventService.prototype.updateEventOrder = function (site, eventIds) {
        return this.put("sites/" + site + "/events", eventIds);
    };
    EventService.prototype.saveEvent = function (siteId, event) {
        if (event.id) {
            return this.put("sites/" + siteId + "/events/" + event.id, event)
                .pipe(map(function (newEvent) { return new Event().deserialize(newEvent); }));
        }
        else {
            return this.post("sites/" + siteId + "/events", event)
                .pipe(map(function (newEvent) { return new Event().deserialize(newEvent); }));
        }
    };
    EventService.prototype.deleteEvent = function (siteId, event) {
        return this.delete("sites/" + siteId + "/events/" + event.id);
    };
    return EventService;
}(ApiService));
export { EventService };
