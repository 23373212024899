import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Gallery } from '../models';

@Component({
  selector: 'app-edit-gallery-dialog',
  templateUrl: './edit-gallery-dialog.component.html',
  styleUrls: ['./edit-gallery-dialog.component.scss']
})
export class EditGalleryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditGalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  ngOnInit() {
  }

  gallerySaved(savedGallery: Gallery) {
    this.dialogRef.close(savedGallery);
  }
}
