import { Component, OnInit, Input, Output, ViewChild, SimpleChanges, EventEmitter, OnChanges } from '@angular/core';
import { Gallery, GalleryItem } from '../models';
import { ReztyImageUploader } from '../image-uploader.service';
import { NgForm } from '@angular/forms';
import { GalleryService } from '../gallery.service';
import { SynapsPicsUtils } from 'ngx-synaps-pics';

@Component({
  selector: 'app-slide-edit',
  templateUrl: './slide-edit.component.html',
  styleUrls: ['./slide-edit.component.scss']
})
export class SlideEditComponent implements OnInit, OnChanges {
  @Input() slideId: string;
  @Input() siteId: string;
  @Output() save = new EventEmitter<Gallery>();
  @Output() saveStart = new EventEmitter<Gallery>();
  @ViewChild('frm') public form: NgForm;

  slide: Gallery;
  uploader: ReztyImageUploader = new ReztyImageUploader({
    path: 'spiroslounge/galleries'
  });

  constructor(
    private galleryService: GalleryService,
    private synapsPicsUtils: SynapsPicsUtils
  ) { }

  ngOnInit() {
    this.slide = new Gallery();
    this.slide.tags = ['slide'];
    this.slide.items = [];

    this.uploader.onSuccessItem = (file, response, status) => {
      const resp = JSON.parse(response);
      this.slide.items.push({
        src: resp.path
      } as GalleryItem);
    };
  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.siteId && this.slideId) {
      this.galleryService.getGallery(this.siteId, this.slideId).subscribe((gallery: Gallery) => {
        // gallery.items = gallery.items.map((item) => {
        //   item.src = this.synapsPicsUtils.getImageUrl({path: item.src});
        //   return item;
        // });

        this.slide = gallery;
      });
    }
  }

  saveSlide() {
    this.galleryService.saveGallery(this.siteId, this.slide).subscribe((gallery: Gallery) => {
      this.save.emit(gallery);
    });
  }
}
