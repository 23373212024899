import { Component, OnInit } from '@angular/core';
import { EventService } from '../event.service';
import { ActivatedRoute } from '@angular/router';
import { Site, Event } from '../models';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EventEditDialogComponent } from '../event-edit-dialog/event-edit-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  events: Event[] = [];
  private site: Site;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.data.pipe(
      tap((data: {site: Site}) => this.site = data.site),
      switchMap((data: {site: Site}) => this.eventService.getSiteEvents(data.site.id))
    )
      .subscribe((events: Event[]) => {
        this.events = events;
      });
  }

  addEvent(event) {
    this.dialog.open(EventEditDialogComponent, {
      disableClose: true,
      minWidth: '300px',
      maxWidth: '90%',
      maxHeight: '90%',
      data: {
        event: event,
        siteId: this.site.id
      }
    }).afterClosed().subscribe((savedEvent: Event) => {
      if (savedEvent) {
        if (!event) {
          this.events.push(savedEvent);
        } else {
          this.events = this.events.map(e => {
            if (e.id === event) {
              e = savedEvent;
            }
            return e;
          });
        }
      }
    });
  }

  deleteEvent(event: Event) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'Are you sure to remove this event?'
      }
    }).beforeClose().subscribe((result: boolean) => {
      if (result) {
        this.eventService.deleteEvent(this.site.id, event).subscribe(() => {
          this.events.splice(this.events.findIndex((ev) => {
            return ev.id === event.id;
          }), 1);
          this.snackBar.open('Event removed!', 'OK', {
            duration: 10000
          });
        });
      }
    });
  }

  orderEvent($event) {
    this.eventService
      .updateEventOrder(this.site.id, this.events.map(event => parseInt(event.id, 10)))
      .subscribe(() => {
      }, () => {
        console.log('failed');
      });
  }

}
