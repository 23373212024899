import { Injectable } from '@angular/core';
import { from, Observable, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Site } from './models';

@Injectable()
export class SiteService extends ApiService<Site> {
  currentSite = new ReplaySubject<Site>(1);

  getCurrentSite(): Observable<Site> {
    return from(this.currentSite);
  }

  setCurrentSite(site: Site) {
    localStorage.setItem('site_id', site.id);
    this.currentSite.next(site);
  }

  getSites(): Observable<Site[]> {
    return this.list('sites').pipe(shareReplay(1));
  }

  getSite(site: string): Observable<Site> {
    return this.get(`sites/${site}`).pipe(shareReplay(1));
  }

  saveSite(site: Site): Observable<Site> {
    return this.patch(`sites/${site.id}`, {
      name: site.name,
      logo: site.logo,
      domains: site.domains,
      languages: ['en']
    });
  }

  addSite(site: Site): Observable<Site> {
    return this.post(`sites`, {
      name: site.name,
      logo: site.logo,
      domains: site.domains,
      languages: ['en']
    });
  }

  addDomain(site: Site, domain: string): Observable<any> {
    return this.post(`sites/${site.id}/domains`, {
      domain: domain
    });
  }

  removeDomain(site: Site, domain: string): Observable<any> {
    return this.delete(`sites/${site.id}/domains/${domain}`);
  }

  saveIntegrations(site: Site): Observable<Site> {
    return this.patch(`sites/${site.id}`, {
      integrations: site.integrations
    });
  }
}
