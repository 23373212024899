import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      noText?: string,
      yesText?: string,
      text: string,
      title?: string
    }) {
      this.data.noText = data.noText || 'No';
      this.data.yesText = data.yesText || 'Yes';
      this.data.title = data.title || 'Confirm';
    }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
