<h2 mat-dialog-title *ngIf="data.gallery">Edit Gallery</h2>
<h2 mat-dialog-title *ngIf="!data.gallery">Add Gallery</h2>

<mat-dialog-content>
  <app-edit-gallery [galleryId]="data.gallery" [siteId]="data.siteId" #editGallery (save)="gallerySaved($event)"></app-edit-gallery>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button color="primary" [disabled]="editGallery.form.invalid" (click)="editGallery.form.ngSubmit.emit()">Save</button>
</mat-dialog-actions>
